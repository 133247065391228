import { defaultFiltersObject } from "./helpers"

export default () => ({
  SET_ITEM: (state, item) => (state.item = item),

  RESET_FILTERS: state => (state.filters = defaultFiltersObject()),

  SET_BASIC_RATE_ITEM_BY_INDEX: (state, { index, item }) => {
    item._updated = true
    state.item.publish_settings.basic_rates.splice(index, 1, item)
  },

  SET_SPECIAL_RATE_ITEM_BY_INDEX: (state, { specialRateIndex, index, item }) => {
    item._updated = true
    state.item.publish_settings.special_rates[specialRateIndex].rates.splice(index, 1, item)
  }
})
